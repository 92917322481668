import * as React from 'react'
import { BiChevronDown, BiChevronUp } from 'react-icons/bi'

import Button from './Button'

const Dropdown = ({ filter, labels, activeLabel, handleActiveLabel }) => {
  const [selected, setSelected] = React.useState(0)
  const [open, setOpen] = React.useState(false)
  const wrapperRef = React.useRef(null)

  React.useEffect(() => {
    setSelected(labels.indexOf(activeLabel))
  }, [activeLabel])

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [])

  const handleClick = (e) => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      setOpen(false)
    }
  }

  const handleSelect = (index) => {
    setSelected(index)
    setOpen(false)
    handleActiveLabel(filter, labels[index])
  }

  return (
    <div ref={wrapperRef} className="relative text-gray-700 dark:text-white">
      <Button onClick={() => setOpen(!open)}>
        <span className="flex items-center gap-2">
          {labels[selected]}
          {open ? <BiChevronUp /> : <BiChevronDown />}
        </span>
      </Button>
      {open && (
        <div className="absolute bg-white dark:bg-black shadow-lg rounded-md overflow-hidden mt-2 z-10">
          <div className="flex flex-col">
            {labels.map((label, index) => (
              <button
                key={label}
                className={`flex items-center justify-center py-2 px-4 cursor-pointer ${
                  index === selected
                    ? 'bg-gray-200 dark:bg-gray-500/90'
                    : 'bg-white dark:bg-gray-500/50'
                } whitespace-nowrap hover:bg-gray-200 dark:hover:bg-gray-500/90`}
                onClick={() => handleSelect(index)}>
                {label}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default Dropdown

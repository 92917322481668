import * as React from 'react'
import { Helmet } from 'react-helmet'
import { BiX } from 'react-icons/bi'
import GoogleAdSense from 'react-adsense'

const AdSense = () => {
  const [show, setShow] = React.useState(true)

  if (!show) {
    return null
  }

  return (
    <div className="relative bg-white dark:bg-gray-500/10 h-36 mb-10 border dark:border-gray-500/50 overflow-hidden cursor-pointer">
      <Helmet>
        <script
          async
          src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
      </Helmet>
      <GoogleAdSense.Google
        client={process.env.GATSBY_ADSENSE_CLIENT}
        slot={process.env.GATSBY_ADSENSE_SLOT}
        style={{ display: 'block' }}
        format="fluid"
      />
      <div className="absolute text-sm top-1 right-1">
        <button
          className="p-1 border border-gray-400/50 hover:border-gray-400/100 transition-all"
          onClick={() => setShow(false)}>
          <BiX />
        </button>
      </div>
    </div>
  )
}

export default AdSense

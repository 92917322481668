import * as React from 'react'

import Header from './Header'
import Footer from './Footer'
import AdSense from './AdSense'

const Layout = ({ children }) => {
  return (
    <div className="bg-slate-50 dark:bg-black/95 dark:text-white/90 min-h-screen">
      <div className="max-w-6xl mx-auto px-4 md:px-8 pt-2 md:pt-4 pb-8">
        <Header />
        <AdSense />
        <main>{children}</main>
      </div>
      <Footer />
    </div>
  )
}

export default Layout

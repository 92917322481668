import * as React from 'react'

import { useEventsContext } from 'contexts/EventsContext'
import Dropdown from './Dropdown'

const EventFilters = () => {
  const [showResetButton, setShowResetButton] = React.useState(false)
  const {
    filters,
    activeFilters,
    activeFiltersOri,
    setActiveFilters,
    initializeActiveFilters
  } = useEventsContext()

  React.useEffect(() => {
    let isFilterChanged = false
    Object.keys(filters).forEach((key) => {
      if (activeFilters[key] !== activeFiltersOri[key]) {
        isFilterChanged = true
      }
    })
    setShowResetButton(isFilterChanged)
  }, [activeFilters])

  const handleActiveFilter = (key, label) => {
    setActiveFilters({ ...activeFilters, [key]: label })
  }

  return (
    <>
      {Object.keys(filters).map((key) => (
        <React.Fragment key={key}>
          {filters[key].length > 0 && (
            <Dropdown
              key={filters[key]}
              filter={key}
              labels={filters[key]}
              activeLabel={activeFilters[key]}
              handleActiveLabel={handleActiveFilter}
            />
          )}
        </React.Fragment>
      ))}
      {showResetButton && (
        <button
          className="text-sm font-light text-gray-700 dark:text-gray-300 whitespace-nowrap px-3 py-2.5 rounded-md hover:bg-gray-400/20 transition-all ease-in-out"
          onClick={() => initializeActiveFilters()}>
          Reset filters
        </button>
      )}
    </>
  )
}

export default EventFilters

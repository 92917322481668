import * as React from 'react'

import { sortedMonths } from 'utils'

const ALL_MONTHS = 'All months'
const ALL_LOCATIONS = 'All locations'
const ALL_STATUS = 'All status'

const initialState: any = {
  allEvents: [],
  filteredEvents: [],
  filters: {},
  activeFilters: {},
  activeFiltersOri: {},
  setActiveFilters: () => {},
  initializeActiveFilters: () => {}
}

const EventsContext = React.createContext(initialState)
export const useEventsContext = () => React.useContext(EventsContext)

const EventsProvider = ({ children }) => {
  const [allEvents, setAllEvents] = React.useState([])
  const [filteredEvents, setFilteredEvents] = React.useState([])
  const [filters, setFilters] = React.useState({
    month: [],
    location: [],
    status: []
  })
  const [activeFilters, setActiveFilters] = React.useState({
    month: '',
    location: '',
    status: ''
  })
  const [activeFiltersOri, setActiveFiltersOri] = React.useState({
    month: '',
    location: '',
    status: ''
  })

  React.useEffect(() => {
    initializeFilters()
    initializeActiveFilters()
  }, [allEvents])

  React.useEffect(() => {
    refreshFilteredEvents()
  }, [allEvents, activeFilters])

  const refreshFilteredEvents = () => {
    const filteredData = allEvents.filter(({ date, location, status }) => {
      const sameMonth = activeFilters.month === date.split(' ')[0]
      const sameLocation = activeFilters.location === location
      const sameStatus = activeFilters.status === status
      return (
        (sameMonth || activeFilters.month === ALL_MONTHS) &&
        (sameLocation || activeFilters.location === ALL_LOCATIONS) &&
        (sameStatus || activeFilters.status === ALL_STATUS)
      )
    })
    setFilteredEvents(filteredData)
  }

  const initializeFilters = () => {
    const months = allEvents.map(({ date }) => date.split(' ')[0])
    const locations = allEvents.map(({ location }) => location)
    const status = allEvents.map(({ status }) => status)
    setFilters({
      month: [ALL_MONTHS, ...sortedMonths(Array.from(new Set(months)))],
      location: [ALL_LOCATIONS, ...Array.from(new Set(locations)).sort()],
      status: [ALL_STATUS, ...Array.from(new Set(status)).sort()]
    })
  }

  const initializeActiveFilters = () => {
    const status = allEvents.map(({ status }) => status).sort()
    const data = {
      month: ALL_MONTHS,
      location: ALL_LOCATIONS,
      status: status.length > 0 ? status[1] : ALL_STATUS
    }
    setActiveFilters(data)
    setActiveFiltersOri(data)
  }

  return (
    <EventsContext.Provider
      value={{
        setAllEvents,
        filteredEvents,
        filters,
        activeFilters,
        activeFiltersOri,
        setActiveFilters,
        initializeActiveFilters
      }}>
      {children}
    </EventsContext.Provider>
  )
}

export default EventsProvider

import * as React from 'react'

const Button = ({ children, ...props }) => {
  return (
    <button
      {...props}
      className="p-2 bg-gray-400/20 hover:border-gray-500/50 border-2 border-transparent transition-all rounded-md whitespace-nowrap">
      {children}
    </button>
  )
}

export default Button

const sortedMonths = (months: string[]) => {
  const allMonths = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
  return allMonths.filter((month) => months.includes(month))
}

export default sortedMonths

import * as React from 'react'

const ImageTiles = ({ sources, alt }) => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 items-center text-center gap-2 p-2">
      {sources.map((source, index) => (
        <img
          key={index}
          src={source}
          alt={alt}
          className="rounded-md max-w-full max-h-[250px] mx-auto"
        />
      ))}
    </div>
  )
}

export default ImageTiles

import * as React from 'react'
import ReactModal from 'react-modal'
import { BiLinkExternal, BiX } from 'react-icons/bi'
import { GoCalendar, GoLocation } from 'react-icons/go'

import Button from './Button'
// import ImageSlider from './ImageSlider'
import ImageTiles from './ImageTiles'

const EventModal = ({ data, isOpen, handleToggleModal }) => {
  // const getImageSlider = () => {
  //   const sources = [data.pic1, data.pic2, data.pic3, data.pic4]
  //   return <ImageSlider sources={sources} alt={data.title} />
  // }

  const getImageTiles = () => {
    const sources = [data.pic1, data.pic2, data.pic3, data.pic4]
    return <ImageTiles sources={sources} alt={data.title} />
  }

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={() => handleToggleModal()}
      className="Modal"
      overlayClassName="Overlay"
      ariaHideApp={false}
      contentLabel="Selected Event">
      <div className="absolute top-2 right-2 font-bold text-white/70 hover:text-white bg-black/80 rounded-md">
        <Button onClick={() => handleToggleModal()}>
          <BiX />
        </Button>
      </div>
      <div className="p-4">
        <h2 className="font-bold text-lg md:text-xl lg:text-2xl xl:text-3xl mb-2">
          {data.title}
        </h2>
        <div className="flex">
          <div className="grow w-0 font-light text-sm lg:text-base xl:text-lg mb-8">
            {data.description}
          </div>
        </div>
        <div className="flex flex-wrap flex-col md:flex-row gap-2 justify-between">
          <div className="w-full font-medium text-sm flex gap-2 items-center">
            <GoLocation /> {data.location}
          </div>
          <div className="font-medium text-sm flex gap-2 items-center">
            <GoCalendar /> {data.date}
          </div>
          <div className="h-2 md:hidden" />
          <Button>
            <a
              href={`https://${data.website}`}
              target="_blank"
              rel="norefferer"
              className="flex items-center justify-center gap-2">
              Open Event <BiLinkExternal />
            </a>
          </Button>
        </div>
      </div>
      <hr className="m-2 border-1 border-dotted border-gray-500/50" />
      {getImageTiles()}
    </ReactModal>
  )
}

export default EventModal

import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          defaultTitle
        }
      }
    }
  `)

  return (
    <div className="px-2 py-8 text-center text-sm md:text-base font-light text-gray-600 dark:text-white/70 bg-gray-400/20">
      &copy; {data.site.siteMetadata.defaultTitle}
      {', '}
      {new Date().getFullYear()}
    </div>
  )
}

export default Footer

import * as React from 'react'
import { ThemeToggler } from 'gatsby-plugin-dark-mode'
import { FiSun, FiMoon } from 'react-icons/fi'

import Button from './Button'

const ThemeToggle = () => {
  return (
    <ThemeToggler>
      {({ theme, toggleTheme }) => (
        <Button
          onClick={() => toggleTheme(theme === 'dark' ? 'light' : 'dark')}>
          <span className="text-lg">
            {theme === 'dark' ? <FiSun /> : <FiMoon />}
          </span>
        </Button>
      )}
    </ThemeToggler>
  )
}

export default ThemeToggle

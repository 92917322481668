import * as React from 'react'

import { useEventsContext } from 'contexts/EventsContext'
import EventFilters from './EventFilters'
import EventCard from './EventCard'

const EventCardList = ({ data }) => {
  const { filteredEvents, setAllEvents } = useEventsContext()

  React.useEffect(() => {
    setAllEvents(data.map(({ node }) => node))
  }, [data])

  return (
    <>
      <div className="flex gap-2 mb-4 flex-wrap">
        <EventFilters />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {filteredEvents.map((event) => (
          <EventCard key={event.id} data={event} />
        ))}
      </div>
    </>
  )
}

export default EventCardList

import * as React from 'react'
import { GoCalendar, GoLocation } from 'react-icons/go'

import Button from './Button'
import EventModal from './EventModal'

const EventCard = ({ data }) => {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <>
      <EventModal
        data={data}
        isOpen={isOpen}
        handleToggleModal={() => setIsOpen(!isOpen)}
      />
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="bg-white dark:bg-gray-500/10 rounded-md overflow-hidden hover:border-gray-500/50 dark:hover:border-gray-500/50 border-2 dark:border-transparent transition-all cursor-pointer flex sm:flex-col group">
        <div className="overflow-hidden w-2/3 sm:w-full">
          <div
            className="h-52 bg-cover bg-center group-hover:scale-110 transition-all duration-200 ease-in-out"
            style={{ backgroundImage: `url(${data.pic1})` }}></div>
        </div>
        <div className="p-4 w-full flex flex-col justify-between">
          <div>
            <div className="font-medium text-lg">{data.title}</div>
            <div className="text-sm font-light line-clamp-3 sm:line-clamp-2 text-gray-700 dark:text-gray-400 my-2">
              {data.description}
            </div>
          </div>
          <div className="mt-4 text-xs font-medium flex gap-2 items-center">
            <GoLocation /> {data.location}
          </div>
          <div className="flex justify-between items-center">
            <div className="text-xs font-medium flex gap-2 items-center">
              <GoCalendar /> {data.shortDate}
            </div>
            <Button>
              <span className="text-sm">See Details</span>
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default EventCard

import * as React from 'react'
import { useLocation } from '@reach/router'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai'

import ThemeToggle from './ThemeToggle'
import Button from './Button'

const Header = () => {
  const { pathname } = useLocation()
  const [showMenu, setShowMenu] = React.useState(false)
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          defaultTitle
          navLinks {
            label
            path
          }
        }
      }
    }
  `)

  const getActiveLinkClassNames = (path: string): string => {
    if (pathname === path || pathname === `${path}/`) {
      return 'font-bold'
    }
    return ''
  }

  return (
    <header className="mb-8">
      <div className="flex flex-wrap justify-between items-center py-4">
        <Link
          to="/"
          className="text-3xl sm:text-4xl lg:text-5xl whitespace-nowrap">
          {data.site.siteMetadata.defaultTitle}
        </Link>
        <div className="flex gap-2 md:order-2">
          <ThemeToggle />
          <div className="md:hidden flex">
            <Button onClick={() => setShowMenu(!showMenu)}>
              {showMenu ? <AiOutlineClose /> : <AiOutlineMenu />}
            </Button>
          </div>
        </div>
        <nav
          className={`${
            showMenu ? 'block' : 'hidden'
          } ml-auto md:mr-2 justify-between items-center w-full md:flex md:w-auto md:order-1`}>
          <ul className="flex flex-col md:flex-row mt-2 md:mt-0">
            {data.site.siteMetadata.navLinks.map(({ path, label }) => (
              <li key={path}>
                <Link
                  to={path}
                  onClick={() => setShowMenu(false)}
                  className={`${getActiveLinkClassNames(
                    path
                  )} block border-b border-gray-500/50 md:border-0 font-light text-gray-700 dark:text-gray-300 whitespace-nowrap px-3 py-2.5 md:py-1.5 rounded-md hover:bg-gray-400/20 transition-all ease-in-out`}>
                  {label}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </header>
  )
}

export default Header
